import makeStyles from '@mui/styles/makeStyles';

export const useFormStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& > div > div': {
      margin: '4px 0 2px 0px',
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    background: '#fff',
    borderRadius: '1%',
  },
}));
