import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UPaper from '../../../components/UPaper';
import Row from './Row';
import UTableHeading from '../../../components/UTableHead';
import MESSAGES from '../../../constants/messages';
import * as api from '../../../api/Egresso/api';

const useListStyles = makeStyles({
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
});

// eslint-disable-next-line react/prop-types
function List({ filteredList, del, flagS }) {
  const classes = useListStyles();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [content, setContent] = useState([]);

  const fetchEgresso = useCallback(async (filters) => {
    const {
      cpf,
      name,
      ciclo,
      curso,
      origemCogna,
    } = filters;

    const { data } = Object.values(filters).length === 0
      ? await api.fetchEgresso({ page, size })
      : await api.fetchEgresso({
        page,
        size,
        cpf,
        name,
        ciclo,
        curso,
        origemCogna,
      });
    setTotalSize(data.totalSize);
    await setContent(data.content);
  }, [page, size, setTotalSize, setContent]);

  useEffect(() => {
    if (flagS === 'excluir') {
      setPage(0);
      setSize(10);
    }
    fetchEgresso(filteredList);
  }, [page, size, fetchEgresso, filteredList, flagS]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={UPaper} gutterTop>
      <Grid container direction="column">
        <Grid item lg={12} className={classes.tableWrapper}>
          <Table aria-label="tabela de resultados">
            <TableHead>
              <TableRow>
                <UTableHeading>Nome</UTableHeading>
                <UTableHeading>CPF</UTableHeading>
                <UTableHeading>Ciclo</UTableHeading>
                <UTableHeading>Curso</UTableHeading>
                <UTableHeading>Origem</UTableHeading>
                <UTableHeading>Opções</UTableHeading>
              </TableRow>
            </TableHead>
            <TableBody>
              {content && content.map((row) => <Row key={row.name} row={row} del={del} />)}
            </TableBody>
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={5}
                  count={totalSize}
                  rowsPerPage={size}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={MESSAGES.mostrarLinhas}
                  labelDisplayedRows={({ from, to, count }) => `${from} a ${to} de ${Number(count)}`}
                  page={page}
                  onPageChange={handleChangePage}
                  backIconButtonProps={{
                    'aria-label': 'Pagina Anterior',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Próxima Página',
                  }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

export default List;
