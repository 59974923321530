import React from 'react';

import { TableRow, TableCell, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const DeleteButton = styled(Button)({
  padding: '6px 12px',
  minWidth: '40px',
  '& span': {
    '& span': {
      marginRight: '0',
    },
  },
});

// eslint-disable-next-line react/prop-types
function Row({ row, del }) {
  const classes = useRowStyles();
  return (
    <>
      <TableRow key={row.name} className={classes.root}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.cpf}</TableCell>
        <TableCell>{row.details.ciclo}</TableCell>
        <TableCell>{row.details.curso}</TableCell>
        <TableCell>{row.details.origemCogna}</TableCell>
        <TableCell>
          <DeleteButton
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttons}
            onClick={() => del(row.cpf)}
            startIcon={<DeleteForeverIcon />}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    details: {
      ciclo: PropTypes.string.isRequired,
      curso: PropTypes.string.isRequired,
      origemCogna: PropTypes.string.isRequired,
    },
  }).isRequired,
  del: PropTypes.func.isRequired,
};

export default Row;
