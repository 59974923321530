import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useScrollTrigger,
  AppBar,
  Avatar,
  IconButton,
  Typography,
  Toolbar,
  Menu,
  MenuItem,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paths } from '../../../router/routes';
import { logout } from '../../../store/ducks/auth';

const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AppToolbar({ onMenuClick }) {
  const username = useSelector((state) => state.auth.username);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = 'user-menu';

  const isMenuOpen = Boolean(anchorEl);

  const handleUserMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleLogOut = () => {
    dispatch(logout());
    history.push(Paths.HOME);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      id={menuId}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogOut}>Sair</MenuItem>
    </Menu>
  );

  return (
    <ElevationScroll>
      <>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open sidebar"
              onClick={onMenuClick}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <Link href={Paths.DASHBOARD} color="inherit" underline="none">
                Gestão de Leads
              </Link>
            </Typography>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleUserMenuOpen}
              color="inherit"
              size="large"
            >
              <Avatar color="primary">{username[0]}</Avatar>
            </IconButton>
          </Toolbar>
        </AppBar>
        {renderMenu}
      </>
    </ElevationScroll>
  );
}

AppToolbar.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
};
