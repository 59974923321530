import React, { useEffect, useState } from 'react';
import {
  Grid, Drawer, Snackbar, Backdrop, CircularProgress, Modal, Alert, AlertTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';
import CloudUpload from '@mui/icons-material/CloudUpload';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PageHeader from '../../components/PageHeader';
import List from './List';
import UFab from '../../components/UFab';
import Filter from './Filter';
import Form from './Form';
import ModalDelete from './Modal';
import * as api from '../../api/Egresso/api';

const useFormStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Egresso() {
  const classes = useFormStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [valueDel, setValueDel] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [titleError, setTitleError] = useState('');
  const [msgError, setMsgError] = useState('');
  const [flagStatus, setFlagStatus] = useState('');

  const toggleDrawer = (value) => {
    setFormOpen(value);
  };

  const toggleModal = (value) => {
    setDialogOpen(value);
  };

  const onReport = async () => {
    try {
      setLoading(true);
      const { data } = await api.reportEgresso();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `egressos-${moment().format('HHmmss')}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError('Erro ao fazer download do relatório');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplateCsv = async () => {
    try {
      setLoading(true);
      const { data } = await api.templateCsv();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template-csv-participante.csv');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError('Erro ao fazer download do template');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const callbackForm = (value) => {
    if (value) {
      setFormOpen(false);
      setSuccess(true);
    }
  };

  const callBackDialog = (value) => {
    if (value) {
      setDialogOpen(false);
      setSuccess(true);
    }
  };

  const del = (value) => {
    setDialogOpen(true);
    setValueDel(value);
  };

  const handleFormFilters = (data) => {
    // eslint-disable-next-line no-shadow
    setFilteredList(data);
  };

  const msgErro = (title, msg, status) => {
    setTitleError(title);
    setMsgError(msg);
    if (status === true && title === 'Success') {
      setFlagStatus('excluir');
      setSuccess(true);
      setError(false);
      setFilteredList({});
      setFlagStatus('');
    } else if (status === true && title === 'Error') {
      setFlagStatus('');
      setSuccess(false);
      setError(true);
    } else {
      setFlagStatus('');
      setSuccess(false);
      setError(false);
    }
  };

  const actions = [
    {
      icon: <PrintIcon />,
      name: 'Exportar relatório CSV',
      color: 'primary',
      onClick: onReport,
    },
    {
      icon: <CloudUpload />,
      name: 'Upload CSV',
      color: 'primary',
      onClick: toggleDrawer,
    },
    {
      icon: <PostAddIcon />,
      name: 'Download template CSV',
      color: 'primary',
      onClick: downloadTemplateCsv,
    },
  ];

  useEffect(() => {
  }, [filteredList, titleError, msgError, flagStatus]);

  return (
    <div>
      <PageHeader title="Listagem de Leads" subheading="Consulta" />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sm={12} md={12} lg={8} style={{ maxWidth: '100%' }}>
          <Filter onSubmit={handleFormFilters} />
          <List filteredList={filteredList} del={del} flagS={flagStatus} />
        </Grid>
        <UFab actions={actions} />
        <Drawer anchor="right" open={formOpen} onClose={() => toggleDrawer(false)}>
          <Form callbackForm={callbackForm} />
        </Drawer>
        <Modal open={dialogOpen} onClose={() => toggleModal(false)}>
          <ModalDelete callBackDialog={callBackDialog} msgErro={msgErro} del={valueDel} />
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={success}
          autoHideDuration={5000}
          onClose={() => setSuccess(false)}
        >
          <Alert variant="outlined" severity="success">
            <AlertTitle>{titleError}</AlertTitle>
            {msgError}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={error}
          autoHideDuration={5000}
          onClose={() => setError(null)}
        >
          <Alert variant="outlined" severity="error">
            <AlertTitle>{titleError}</AlertTitle>
            {msgError}
          </Alert>
        </Snackbar>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Egresso;
