import { createTheme } from '@mui/material/styles';

const palette = {
  primary: { main: '#9960E3', contrastText: '#ffffff' },
  secondary: { main: '#8948DF' },
};

export const drawerWidth = 240;

export default createTheme({ palette, spacing: 4 });
