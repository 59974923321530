import React from 'react';
import {
  Typography, Paper, Grid, Box, CssBaseline,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Wallpaper from '../../assets/images/wallpaper.jpg';
import { ReactComponent as Marcas } from '../../assets/images/marcas.svg';
import Form from './Form';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Wallpaper})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: 'linear-gradient(to bottom, transparent 20%, #000 85%)',
      opacity: 0.6,
    },
  },
  paper: {
    margin: theme.spacing(8, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& > :first-child': {
      marginLeft: theme.spacing(1),
    },
  },
  copyrightContainer: {
    alignSelf: 'flex-end',
  },

  heading: {
    alignSelf: 'flex-start',
  },
}));

function Login() {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        component={Paper}
        elevation={6}
        square
        justifyContent="center"
      >
        <Grid item container md={6}>
          <div className={classes.paper}>
            <Box className={classes.heading}>
              <Typography component="h1" variant="h5" gutterBottom={false}>
                Seja bem-vindo.
              </Typography>
              <Typography variant="caption">
                Informe suas credencias para acessar o sistema.
              </Typography>
            </Box>
            <Form />
          </div>
        </Grid>
        <Grid item container justifyContent="center">
          <Marcas width="70%" height="70" className={classes.copyrightContainer} />
        </Grid>
      </Grid>
      <Grid item xs={false} sm={6} md={6} className={classes.image} />
    </Grid>
  );
}

export default Login;
