import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useHeadingStyles = makeStyles({
  cell: {
    fontWeight: 'bold',
  },
});

function UTableHeading({ children, ...rest }) {
  const classes = useHeadingStyles();
  return (
    <TableCell className={classes.cell} {...rest}>
      {children}
    </TableCell>
  );
}

UTableHeading.defaultProps = {
  children: null,
};

UTableHeading.propTypes = {
  children: PropTypes.node,
};

export default UTableHeading;
