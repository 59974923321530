import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useButtonStyles = makeStyles((theme) => ({
  buttonWrapper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

function UButton({ children, ...buttonProps }) {
  const classes = useButtonStyles();

  return (
    <div className={classes.buttonWrapper}>
      <Button
        {...buttonProps}
      >
        {children}
      </Button>
    </div>
  );
}

UButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UButton;
