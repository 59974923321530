import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Snackbar, Backdrop, CircularProgress, Alert, AlertTitle,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from '@emotion/styled';
import UButton from '../../../components/UButton';
import * as api from '../../../api/Egresso/api';

const StyledButton = styled(UButton)` 
  margin: ${({ theme }) => theme.spacing(1)};
`;

const StyledBackdrop = styled(Backdrop)` 
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: '#fff';
`;
function Form({ callbackForm }) {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onSend = async () => {
    try {
      setLoading(true);
      const { data } = await api.upload(file);
      callbackForm(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  }, [file]);

  return (
    <>
      <form style={{ width: '400px', padding: '80px' }}>
        <Grid style={{
          alignItems: 'center',
          textAlign: 'center',
        }}
        >
          <label htmlFor="raised-button-file">
            <input
              accept=".csv"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple={false}
              type="file"
              onChange={onChangeFile}
            />
            <StyledButton
              type="button"
              variant="outlined"
              component="span"
              fullWidth
              startIcon={<CloudUploadIcon />}
              disabled={loading}
            >
              Upload CSV
            </StyledButton>
          </label>
          {file === undefined
            ? (
              <Typography variant="subtitle2">Nenhum arquivo selecionado</Typography>
            ) : (
              <>
                <Typography variant="subtitle2">{file.name}</Typography>
                <StyledButton
                  type="button"
                  variant="contained"
                  color="primary"
                  component="span"
                  fullWidth
                  onClick={onSend}
                  disabled={loading}
                >
                  Enviar
                </StyledButton>
              </>
            )}
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={error}
            autoHideDuration={8000}
            onClose={() => setError(false)}
          >
            <Alert variant="filled" severity="error">
              <AlertTitle>Erro ao Importar</AlertTitle>
              Revise o CSV.
              <br />
              Utilize o template padrão (Com cabeçalho) disponível para download.
            </Alert>
          </Snackbar>
        </Grid>
      </form>
      <StyledBackdrop open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
    </>
  );
}

Form.propTypes = {
  callbackForm: PropTypes.func.isRequired,
};

export default Form;
