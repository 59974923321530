import { getTenantId } from './tenantResolver';

export const TENANT_ID = getTenantId();

export const APP_CONFIG_LOCAL_DEVELOPMENT = {
  api: `https://${TENANT_ID}.stage.platosedu.io/api`,
  ws: 'ws://localhost:8088',
};

export const APP_CONFIG_DEVELOPMENT = {
  api: `https://${TENANT_ID}.dev.platosedu.io/api`,
  ws: `ws://${TENANT_ID}.dev.platosedu.io/api`,
};

export const APP_CONFIG_STAGE = {
  api: `https://${TENANT_ID}.stage.platosedu.io/api`,
  ws: `ws://${TENANT_ID}.stage.platosedu.io/api`,
};

export const APP_CONFIG_PRODUCTION = {
  api: `https://${TENANT_ID}.platosedu.io/api`,
  ws: `ws://${TENANT_ID}.platosedu.io/api`,
};

export const CONFIG = (() => {
  const env = process.env.REACT_APP_ENV;

  switch (env) {
    case 'production': {
      return APP_CONFIG_PRODUCTION;
    }

    case 'development': {
      return APP_CONFIG_DEVELOPMENT;
    }

    case 'stage': {
      return APP_CONFIG_STAGE;
    }

    default:
    case 'local': {
      return APP_CONFIG_DEVELOPMENT;
    }
  }
})();
