import { getTenantId } from '../constants/tenantResolver';

const TOKEN_KEY = 'authToken';

export function getTokenFromLocalStorage() {
  const tenantTokenKey = `${getTenantId()}_${TOKEN_KEY}`;
  return localStorage.getItem(tenantTokenKey);
}

export function saveTokenLocalStorage(token) {
  const tenantTokenKey = `${getTenantId()}_${TOKEN_KEY}`;
  localStorage.setItem(tenantTokenKey, token);
}

export function removeTokenFromLocalStorage() {
  const tenantTokenKey = `${getTenantId()}_${TOKEN_KEY}`;
  localStorage.removeItem(tenantTokenKey);
}
