import React from 'react';
import { TextField, alpha } from '@mui/material';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import styled from '@emotion/styled';

const StyledTextField = styled(TextField)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  width: 100%; 
  padding: ${({ theme }) => theme.spacing(1)};

  label + & {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }

  & > div {
    height: auto !important;
  }

  & input + div {
    align-self: center;
    margin-left: 0;
    position: absolute;
    right: 1;
  }

  & input + div > button {
    &:hover {
      background: transparent;
    }
  }

  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    min-height: 40px;
    
    &:focus {
      border-color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: 0 0 0 0.2rem ${({ theme }) => alpha(theme.palette.primary.main, 0.25)};
    }

    &:disabled {
      background: ${({ theme }) => theme.palette.grey[50]};
      color: ${({ theme }) => theme.palette.text.disabled};
    }
  }

  .MuiInputBase-root.Mui-error {
    & > input {
      border-color: ${({ theme }) => theme.palette.error.main};
      &:focus {
        border-color: ${({ theme }) => theme.palette.error.main};
        box-shadow: 0 0 0 0.2rem ${({ theme }) => alpha(theme.palette.error.main, 0.25)};
      }
    }
  }

  .MuiFormHelperText-root {
    margin-left: ${({ theme }) => theme.spacing(1)};
    font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  }
`;

function UTextField({
  name, error, type, multiline, InputProps, InputLabelProps, FormHelperTextProps, ...rest
}) {
  const rows = multiline ? { rows: 2 } : null;

  return (
    <StyledTextField
      error={error}
      id={name}
      name={name}
      type={type}
      {...rows}
      multiline={multiline}
      InputProps={{
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: true,
        error,
        ...InputLabelProps,
      }}
      FormHelperTextProps={{
        error,
        ...FormHelperTextProps,
      }}
      {...rest}
    />
  );
}

UTextField.defaultProps = {
  error: false,
  InputLabelProps: null,
  InputProps: null,
  FormHelperTextProps: null,
  multiline: false,
  name: null,
  type: 'text',
};

UTextField.propTypes = {
  error: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default UTextField;
