import React from 'react';
import clsx from 'clsx';
import { lighten, alpha, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.common.white,
    borderBottomColor: lighten(alpha(theme.palette.divider, 1), 0.9),
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  gutterBottom: {
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(3),
    },
  },
  text: {
    color: theme.typography.body1.color,
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(6),
    },
  },
  subheading: {
    alignItems: 'center',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 48,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
}));

function PageHeader({ title, subheading, gutterBottom }) {
  const classes = useStyles();

  const renderTitle = () => (typeof title === 'string' ? (
    <Typography
      variant="h5"
      component="h1"
      classes={{ colorPrimary: classes.text }}
      className={classes.title}
      color="primary"
      noWrap
    >
      {title}
    </Typography>
  ) : null);

  const renderSubheading = () => (typeof subheading === 'string' ? (
    <Typography
      variant="body1"
      classes={{ body1: classes.subheading }}
      color="primary"
      noWrap
    >
      {subheading.toUpperCase()}
    </Typography>
  ) : null);

  return (
    <header className={clsx(classes.header, gutterBottom ? classes.gutterBottom : '')}>
      <div className={classes.headerInner}>
        {renderTitle()}
        {renderSubheading()}
      </div>
    </header>
  );
}

PageHeader.defaultProps = {
  subheading: null,
  gutterBottom: true,
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  gutterBottom: PropTypes.bool,
};

export default PageHeader;
