import React, { useState, useEffect } from 'react';
import {
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Dashboard as DashboardIcon,
  SchoolSharp as EgressoIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import SideDrawer from './SideDrawer';
import AppToolbar from './AppToolbar';
import { Paths } from '../../router/routes';
import { drawerWidth } from '../../theme';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
}));

const InternalTemplate = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const isMobileBreakpoint = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(!isMobileBreakpoint);

  const toggleDrawerOpen = () => setDrawerOpen(!drawerOpen);

  useEffect(() => {
    if (setDrawerOpen && isMobileBreakpoint) {
      setDrawerOpen(false);
    }
  }, [setDrawerOpen, isMobileBreakpoint]);

  const handleEgresso = () => history.push(Paths.EGRESSO);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppToolbar onMenuClick={toggleDrawerOpen} />
      <SideDrawer open={drawerOpen} onClose={toggleDrawerOpen}>
        <Hidden mdDown implementation="css">
          <div className={classes.appBarSpacer} />
        </Hidden>
        <List>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleEgresso}>
            <ListItemIcon>
              <EgressoIcon />
            </ListItemIcon>
            <ListItemText primary="Egressos Cogna" />
          </ListItem>
        </List>
      </SideDrawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
};

InternalTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InternalTemplate;
