import React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { useFormStyles } from './styles';
import * as api from '../../../api/Egresso/api';

// eslint-disable-next-line react/prop-types
function ModalDelete({ callBackDialog, msgErro, del }) {
  const classes = useFormStyles();

  const handleClose = () => {
    callBackDialog(true);
    msgErro('', '', false);
  };

  const handleConfirm = async () => {
    try {
      await api.del(del);
      msgErro('Success', 'Usuário excluído com sucesso', true);
      callBackDialog(true);
    } catch (error) {
      msgErro('Erro', 'Erro ao excluir o usuário', true);
      callBackDialog(true);
    }
  };

  return (
    <>
      <div className={classes.modal}>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={12}>
            <Box paddingTop={3} textAlign="center">
              <Typography>
                Deseja mesmo excluir?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleClose}
              >
                Fechar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box textAlign="center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleConfirm}
              >
                Confirmar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box paddingBottom={2} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ModalDelete;
